<template>
    <div :is="layout">
        <div id="cesiumContainer"></div>
<!--        <div-->
<!--                @click="is_minimize = !is_minimize"-->
<!--                :class="{-->
<!--        info_box: is_minimize,-->
<!--        minimize: !is_minimize,-->
<!--      }"-->
<!--        >-->
<!--            <div v-show="is_minimize">-->
<!--                <div style="font-size: 16px" class="infobox__title">{{$t('airport.conflict')}}</div>-->
<!--                <div-->
<!--                        style="font-size: 12px; margin-top: 30px"-->
<!--                        v-if="!seat_conflict_data.length"-->
<!--                >-->
<!--                    {{$t('airport.No_conflict')}}-->
<!--                </div>-->
<!--                <p v-for="i in seat_conflict_data" style="font-size: 12px">-->
<!--                    {{ getTime(i.land_time, "yyyy-MM-dd hh:mm:ss") }} {{$t('airport.will')}}-->
<!--                    {{ i.seat_name }} {{$t('airport.gate_position')}}{{$t('airport.be_conflict')}}-->
<!--                </p>-->
<!--            </div>-->
<!--        </div>-->
        <v-dialog width="800px" v-model="dialog">
            <v-card>
                <v-card-title>
                    {{$t('airport.details')}}
                </v-card-title>
                <v-container class="text-right text--primary" style="margin-right: 20px">
                    <span>{{$t('airport.total_time')}}:</span>
                    <span>{{count_time}}</span>
                    <span>s</span>
                </v-container>
                <v-row>
                    <v-col>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                <tr>
                                    <th class="text-center">
                                        {{$t('flight.flight_number')}}
                                    </th>
                                    <th class="text-center">
                                        {{$t('flight.land_time')}}
                                    </th>
                                    <th class="text-center">
                                        {{$t('flight.leave_time')}}
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr
                                        v-for="item in seat_info"
                                        :key="item.name"
                                >
                                    <td class="text-center">{{ item.number }}</td>
                                    <td class="text-center">{{ getTime(item.land_time, 'yyyy-MM-dd hh:mm') }}</td>
                                    <td class="text-center">{{ getTime(item.leave_time, 'yyyy-MM-dd hh:mm') }}</td>
                                </tr>
                                <!--                当没有数据的时候-->
                                <tr v-if="!seat_info.length">
                                    <td class="text-center" style="user-select: none" colspan="3">
                                        {{$t('airport.alert_text')}}
                                    </td>
                                </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">{{$t('confirm')}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="flag" v-if="flight_date.length" width="1000px">
            <v-card>
                <v-data-table
                        :headers="headers"
                        :items="flight_date"
                        hide-default-footer
                        class="elevation-1"
                >
                    <template v-slot:[`item.predict_time`]="{item}">
                        {{item.fly_time}}
                    </template>
                    <template v-slot:[`item.wake_interval`]="{item}">
                        <span v-if="item.wake_interval<10">轻型</span>
                        <span v-else-if="item.wake_interval<20">中型</span>
                        <span v-else>重型</span>
                    </template>
                </v-data-table>
            </v-card>
        </v-dialog>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs"
                       color="white"
                       text
                       outlined
                       v-on="on"
                       @click="boolean=!boolean"
                       style="position:absolute;left:10px;background:white;top:10px;"
                       class="ml-2" x-small fab>
                    <i class="airport small-font icon-gantetu"
                       style='font-size: 18px !important; color: black '></i>
                </v-btn>
            </template>
            <span>{{$t("flight.gantt")}}</span>
        </v-tooltip>
        <gantt v-show="boolean" @change="changeDate" :start_time="this.$route.params.start_time"
               :end_time="this.$route.params.end_time" :state="true" class="gantt"></gantt>
    </div>
</template>
<script>
    import {get_aircraft_path, click_event} from "../../../api/aircraftdockpre";
    import {get} from "../../../api/flight";
    import Gantt from '@/pages/flightInfo/flightpreview/index.vue'

    export default {
        components: {Gantt},
        data() {
            return {
                data: [],
                list: [],
                is_minimize: false,
                seat_conflict_data: [],
                dialog: false,
                layout: null,
                seat_info: [],
                flag: false,
                boolean: false,
                count_time: 0,
                flight_info: {},
                flight_date: [],
                current_date: null,
            };
        },

        methods: {
            changeDate(e) {
                this.current_date = e;
            },
             RandomNum(Min,Max){
                var num = Min + Math.round(Math.random() * (Max - Min)); 
                return num;
            },
            formatDateTime(date) {
                var y = date.getFullYear();
                var m = date.getMonth() + 1;
                m = m < 10 ? ('0' + m) : m;
                var d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                var h = date.getHours();
                h=h < 10 ? ('0' + h) : h;
                var minute = date.getMinutes();
                minute = minute < 10 ? ('0' + minute) : minute;
                var second=date.getSeconds();
                second=second < 10 ? ('0' + second) : second;
                return y + '-' + m + '-' + d+' '+h+':'+minute+':'+second;
                },
            init() {
                // 飞机停靠路线预览
              var viewer = new this.Cesium.Viewer("cesiumContainer", {
                    infoBox: false,
                    selectionIndicator: false,
                    shadows: true,
                    shouldAnimate: true,
                });
                window.viewer= viewer
                // 设置home默认位置
                this.Cesium.Camera.DEFAULT_VIEW_RECTANGLE =
                    this.Cesium.Rectangle.fromDegrees(
                        -122.3801087,
                        37.62226498,
                        -122.3801087,
                        37.62226498
                    );
                // 设置home默认视口大小
                this.Cesium.Camera.DEFAULT_VIEW_FACTOR = 0.0011;
                window.viewer.animation.viewModel.dateFormatter = (
                    datetime,
                    viewModel,
                    ignoredate
                ) => {
                    var julianDT = new this.Cesium.JulianDate();
                    this.Cesium.JulianDate.addHours(datetime, 8, julianDT);
                    var gregorianDT = this.Cesium.JulianDate.toGregorianDate(julianDT);
                    var objDT;
                    if (ignoredate) {
                        objDT = "";
                    } else {
                        objDT = new Date(
                            gregorianDT.year,
                            gregorianDT.month - 1,
                            gregorianDT.day
                        );
                        objDT =
                            gregorianDT.year +
                            "年" +
                            objDT.toLocaleString("zh-cn", {month: "short"}) +
                            gregorianDT.day +
                            "日";
                        if (viewModel || gregorianDT.hour + gregorianDT.minute === 0) {
                            return objDT;
                            objDT += "";
                        }
                    }
                    return (
                        objDT +
                        this.Cesium.sprintf(
                            "%02d:%02d:%02d",
                            gregorianDT.hour,
                            gregorianDT.minute,
                            gregorianDT.second
                        )
                    );
                };
                const closeLoading = this.$loading()
                get_aircraft_path(this.$route.params).then((res) => {
                    this.data = res.data;
                    this.seat_conflict_data = res.seat_conflict_data;
                    const all_flight_seat = res.all_flight_seat

                    // 创建自定义数据源
                     window.customDataSource = new this.Cesium.CustomDataSource(
                        "customDataSource"
                    );
                    // 以圆形为标记,显示机位
                    for (let i of all_flight_seat) {
                       window.customDataSource.entities.add(new this.Cesium.Entity({
                            id: `${i.id}`,
                            name: 'seat',
                            position: this.Cesium.Cartesian3.fromDegrees(parseFloat(i.lng), parseFloat(i.lat)),
                            ellipse: {
                                show: true,
                                material: this.Cesium.Color.RED.withAlpha(.5),
                                semiMajorAxis: 10,
                                semiMinorAxis: 10
                            },
                            // label: {
                            //   show: true,
                            //   text: i.id.toString()
                            // }
                        }))
                    }
                    // 分组  离开和进场
                    // group=JSON.parse(JSON.stringify(this.data))
                    // console.log(group,"group");
                    // this.data.forEach((item) => {
                    //     if(item.type=="takeoff"){
                    //         item.group_id=1
                    //     }else if(item.type=="land"){
                    //         item.group_id=0
                    //     }
                    // });
                    //   this.data.sort((a, b) => {
                    //       return a.land_time - b.land_time;
                    //  });
                    window.viewer._cesiumWidget._creditContainer.style.display = "none";
                    window.start = this.Cesium.JulianDate.fromDate(
                        new Date(new Date(this.$route.params.start_time).getTime() + 8 * 60 * 60 * 1000 ));
                    let duration =
                        new Date(this.$route.params.end_time).getTime() -
                        new Date(this.$route.params.start_time).getTime();
                    duration = Math.round(duration / 1000);
                    window.stop = this.Cesium.JulianDate.addSeconds(
                        window.start,
                        duration,
                        new this.Cesium.JulianDate()
                    );
                    window.viewer.clock.startTime = window.start.clone();
                    // 设置时钟当前时间
                    var clock= this.$route.params.start_time
                    window.clock=clock
                    window.viewer.clock.currentTime = window.clock;
                    // viewer.clock.currentTime = start1.clone();
                    // 设置时钟停止时间
                    window.viewer.clock.stopTime = stop.clone();
                    // 时间速率，数字越大时间过的越快
                    window.viewer.clock.multiplier = 10;
                    // 时间轴
                    window.viewer.timeline.zoomTo(window.start, window.stop);
                    // 循环执行,即为2，到达终止时间，重新从起点时间开始
                    window.viewer.clock.clockRange = this.Cesium.ClockRange.LOOP_STOP;
                    window.viewer.scene.globe.enableLighting = true;
                    var i=0
                    this.data.forEach(ele=> {
                            ele.id=i;
                            i++;
                            let location = [];
                            let sum=0;
                            ele.position_path=[];
                            const property = new this.Cesium.SampledPositionProperty();
                            const geodesic = new this.Cesium.EllipsoidGeodesic();
                               if(ele&&ele.path){
                                ele.path = Object.values(
                                ele.path.reduce((pre, cur) => {
                                    return {
                                        ...pre,
                                        [JSON.stringify(cur)]: cur,
                                    };
                                }, {})
                                );
                                ele.path.forEach((item, index) => {
                                ele.position_path.push(item.lng,item.lat)
                                if (index > 0) {
                                    let end1 = this.Cesium.Cartographic.fromDegrees(
                                        item.lng,
                                        item.lat
                                    );
                                    let start1 = this.Cesium.Cartographic.fromDegrees(
                                        ele.path[index - 1].lng,
                                        ele.path[index - 1].lat
                                    );
                                    geodesic.setEndPoints(start1, end1);
                                    let distance = geodesic.surfaceDistance;
                                    location.push(distance);
                                    sum+=distance
                                }
                            });
                            }
                            // 速度(m/s)
                            let speed = Math.ceil(sum/((new Date(ele.slipping_time).getTime()-new Date(ele.land_time).getTime())/100));
                            // 飞机飞行时间
                            let eachtime = [];
                            location.reduce((pre, item) => {
                                pre = pre + item / speed;
                                eachtime.push(pre);
                                return pre;
                            }, 0);
                            eachtime.unshift(0);
                            ele.overTime=Math.ceil(eachtime[eachtime.length-1])
                            // 创建开始朱利安时间
                            const _start_time = this.Cesium.JulianDate.fromDate(
                               new Date(new Date(ele.land_time).getTime() + 8 * 60 * 60* 1000)
                            );
                            if(ele&&ele.path){
                            ele.path.forEach((item, index) => {
                                const time = this.Cesium.JulianDate.addSeconds(
                                    _start_time,
                                    Math.round(eachtime[index]),
                                    new this.Cesium.JulianDate()
                                );
                                const position = this.Cesium.Cartesian3.fromDegrees(
                                    item.lng,
                                    item.lat,
                                    item.height
                                );
                                property.addSample(time, position);
                            }, {});
                            ele.property=property
                            }
                            window.viewer.dataSources.add(window.customDataSource);
                            //设置摄像头定位到模型处
                            window.viewer.camera.flyTo({
                                destination: this.Cesium.Cartesian3.fromDegrees(
                                    -122.3801087,
                                    37.62226498,
                                    7500.0
                                ), // 洛杉矶机场
                            });
                    });
                    // 取消双击默认事件
                    window.viewer.cesiumWidget.screenSpaceEventHandler.removeInputAction(
                        this.Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
                    );
                    // 点击显示、隐藏路径
                    const handler = new this.Cesium.ScreenSpaceEventHandler(
                        window.viewer.scene.canvas
                    );
                    var timeout = null;
                    // 双击
                    handler.setInputAction((movement) => {
                        clearTimeout(timeout)
                        timeout = null;
                        var scene = window.viewer.scene
                        if (scene.mode !== this.Cesium.SceneMode.MORPHING) {
                            var pickedObject = scene.pick(movement.position);
                            if (scene.pickPositionSupported && this.Cesium.defined(pickedObject) && pickedObject.node) {
                                var aircraft_type = pickedObject.id.id.split("_")[1];
                                get({number: pickedObject.id.name}).then(res => {
                                    this.flight_date = []
                                    const info = res.results[0]
                                    if (aircraft_type =='takeoff') {
                                        this.flight_info.type = "起飞";
                                        this.flight_info.fly_time = `${info.takeoff_time}`
                                        this.flight_info.runway = `${info.takeoff_runway}`
                                        this.flight_info.gate = `${info.takeoff_seat}`
                                        this.flight_info.arrival_time =this.formatDateTime(new Date((new Date(info.takeoff_time).getTime()/1000+info.takeoff_taxiing_time)*1000))
                                        this.flight_info.arrival_stand_use_time = `${info.takeoff_taxiing_time}`
                                    } else if(aircraft_type =='land'){
                                        this.flight_info.type = "降落";
                                        this.flight_info.fly_time = `${info.land_time}`
                                        this.flight_info.runway = `${info.land_runway}`
                                        this.flight_info.gate = `${info.land_seat}`
                                        this.flight_info.arrival_time = this.formatDateTime(new Date((new Date(info.land_time).getTime()/1000+info.land_taxiing_tiem)*1000))
                                        this.flight_info.arrival_stand_use_time = `${info.land_taxiing_tiem}`
                                    }
                                    var time = window.viewer.clock.currentTime.toString().replace(/T/, ' ').replace(/Z/, '').replace(/\.\d+/g, '');
                                    var cartesian = window.viewer.scene.pickPosition(movement.position);
                                    if (this.Cesium.defined(cartesian)) {
                                        var cartographic = this.Cesium.Cartographic.fromCartesian(cartesian);
                                        var lng = this.Cesium.Math.toDegrees(cartographic.longitude).toFixed(8);
                                        var lat = this.Cesium.Math.toDegrees(cartographic.latitude).toFixed(8);
                                        this.flight_date.push({
                                            lng: lng,
                                            lat: lat,
                                            number: pickedObject.id.name,
                                            time: time, ...this.flight_info
                                        })
                                    }
                                    this.flag = true
                                })
                            }
                        }
                    }, this.Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);


                    handler.setInputAction((movement) => {
                        clearTimeout(timeout)
                            const pick = window.viewer.scene.pick(movement.position);
                            if (pick) {
                                // 判断是不是机位
                                if (pick.id.name === 'seat') {
                                    // 点击机位操作

                                    click_event({
                                        start_time: this.$route.params.start_time,
                                        end_time: this.$route.params.end_time,
                                        seat: pick.id.id
                                    }).then(res => {
                                        // 在这里获取到当前点击的停机位占用表
                                        this.seat_info = res.data
                                        this.count_time = res.count_time
                                        this.dialog = true
                                    })
                                    return
                                }
                                timeout = window.setTimeout(() => {
                               var boolean = pick.id.polyline.show._value;
                               pick.id.polyline.show =!boolean;
                                }, 200)
                            }
                       
                    }, this.Cesium.ScreenSpaceEventType.LEFT_CLICK);
                }).finally(() => {
                    closeLoading()
                });
                var arr=[]
                window.viewer.clock.onTick.addEventListener(clock=> {
                let current=new Date(clock.currentTime).getTime()
                for(let i of this.data){
                //  开始时间
                let begin= (new Date(i.land_time).getTime()+8 * 60 * 60*1000)
                let end=begin+i.overTime*1000
                //  添加模型
                if(current>=begin&&current<=end){
                    if(arr.indexOf(i.id)==-1){
                        arr.push(i.id)
                        window.customDataSource.entities.add(
                        new this.Cesium.Entity({
                            id: `${i.number}_${i.type}`,
                            position: i.property,
                            name: `${i.number}`,
                            // 由开始和停止时间定义的时间间隔
                            availability: new this.Cesium.TimeIntervalCollection([
                                new this.Cesium.TimeInterval({start: window.start, stop: window.stop}),
                            ]),
                            // 根据所提供的速度计算模型的朝向
                            orientation:new this.Cesium.VelocityOrientationProperty(i.property),
                            model: new this.Cesium.ModelGraphics({
                                show: true,
                                uri: "https://slhz-1255710621.cos.ap-nanjing.myqcloud.com/Cesium_Air.glb",
                                minimumPixelSize: 64, //最小的模型像素
                                maximumScale: 256, //最大的模型像素
                                runAnimations: true, //是否显示动画
                                clampAnimations: true, //是否保持最后一针的动画
                                color: this.Cesium.Color.WHITE, //颜色
                            }),
                            path: {
                                show: true,
                                leadTime: 60,
                                trailTime: 60,
                                width: 10,
                                resolution: 1,
                                material: new this.Cesium.PolylineGlowMaterialProperty({
                                    glowPower: 0.3,
                                    color: this.Cesium.Color.PALEGOLDENROD,
                                }),
                            },
                                polyline: {
                                show:false,
                                positions: this.Cesium.Cartesian3.fromDegreesArray(i.position_path),
                                width: 1,
                                material: this.Cesium.Color.BLUE,
                                }
                        })
                    );
                        window.viewer.dataSources.add(window.customDataSource);
                    }
                }else{
                    if(arr.indexOf(i.id)!=-1){
                        arr.splice(arr.indexOf(i.id),1)
                        window.customDataSource.entities.removeById(`${i.number}_${i.type}`)
                    }
                   
                }
                }
                });
            },
        },
        mounted() {
            this.layout = this.$route.meta.use_layout
            this.$store.commit('set_air_port_id', this.$route.params.airport_id)
            this.$nextTick(() => {
                this.init();
            });
        },
        watch: {
            current_date: {
                handler: function (val) {
                    window.clock = this.Cesium.JulianDate.fromDate(
                        new Date(new Date(val.split('_')[0]).getTime() + 8 * 60 * 60 * 1000)
                    );
                    window.viewer.clock.currentTime = window.clock;
                },
            },
        },
        computed: {
            headers() {
                return [
                    {
                        text: this.$t('flight.number'),
                        align: 'center',
                        value: 'number',
                        sortable: false
                    },
                    {
                        text: this.$t('flight.type'),
                        align: 'center',
                        value: 'type',
                        sortable: false
                    },
                    {
                        text: this.$t('flight.current_time'),
                        align: 'center',
                        value: 'time',
                        sortable: false
                    },
                    {
                        text: this.$t('flight.lat'),
                        align: 'center',
                        value: 'lat',
                        sortable: false
                    },
                    {
                        text: this.$t('flight.lng'),
                        align: 'center',
                        value: 'lng',
                        sortable: false
                    },
                    {
                        text: this.$t('flight.gate'),
                        align: 'center',
                        value: 'gate',
                        sortable: false
                    },
                    {
                        text: this.$t('flight.arrival_time'),
                        align: 'center',
                        value: 'arrival_time',
                        sortable: false
                    },
                    {
                        text: this.$t('flight.runway'),
                        align: 'center',
                        value: 'runway',
                        sortable: false
                    },
                    {
                        text: this.$t('flight.predict_time'),
                        align: 'center',
                        value: 'predict_time',
                        sortable: false
                    },

                    {
                        text: this.$t('flight.fly_time'),
                        align: 'center',
                        value: 'fly_time',
                        sortable: false
                    },
                    {
                        text: this.$t('flight.slipping_time'),
                        align: 'center',
                        value: 'arrival_stand_use_time',
                        sortable: false
                    },
                    {
                        text: this.$t('flight.wake_interval'),
                        align: 'center',
                        value: 'wake_interval',
                        sortable: false
                    },
                ]
            }
        }
    };
</script>
<style lang="stylus" scoped>
    .info_box {
        position: absolute;
        left: 60px;
        top: 10px;
        height: 300px;
        width: 300px;
        background: #272b2b;
        border: 1px solid #555;
        border-radius: 4px;
        padding: 10px;
        font-size: 16px;
        user-select: none;
        transition: all 0.3s ease;
        box-sizing: border-box;
        color: white;
    }

    .minimize {
        position: absolute;
        left: 60px;
        top: 10px;
        height: 30px;
        width: 30px;
        background: #272b2b;
        overflow: hidden;
        transition: all 0.3s ease;
    }

    .gantt {
        position: absolute;
        background: rgba(255, 255, 255, 0.5);
        width: 800px;
        height: 80vh;
        z-index: 1;
        top: 50px;
        right: 10px;
        overflow: auto
    }

    .gantt /deep/ #gantt_here
        background rgba(255, 255, 255, 0.3) !important

    .gantt /deep/ #gantt_here
        .gantt_data_area, .gantt_task_cell, .gantt_task_row, .gantt_grid_scale, .gantt_container, .gantt_task_row.gantt_selected, .gantt_task_scale, .gantt_grid_data, .gantt_row gantt_row_task, .gantt_row, .gantt_row_task, .gantt_grid_scale *
            background rgba(255, 255, 255, 0.3) !important

        & *
            color #444 !important
            border-color #444 !important
</style>
